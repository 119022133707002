<template>
  <div class="mine-container">
    <div class="mine-wrapper" v-if="isShow">
      <el-button
        type="text"
        class="back-button"
        size="mini"
        icon="el-icon-arrow-left"
        @click="$router.push({ name: 'Mine' })"
      ></el-button>
      <div class="ticket-information-wrap">
        <!-- <div class="head-portrait">
          <image src="{{avatarUrl}}" />
        </div> -->
        <div class="head-title">
          <span></span>
        </div>
        <div class="ticket-title">{{ order.ticketName }}</div>
        <div class="ticket-information">
          <div>
            <span>购票人</span>
            <span>{{ order.customerName }}</span>
          </div>
          <div>
            <span>手机号</span>
            <span>{{ order.mobile }}</span>
          </div>
          <div>
            <span>公司</span>
            <span>{{ order.company }}</span>
          </div>
          <div>
            <span>购票数量</span>
            <span>{{ order.ticketCount }}</span>
          </div>
          <div v-if="seats.length">
            <span>选购座位</span>
            <span>
              <span class="seat" v-for="(item, i) in seats" :key="i"
                >{{ item.area }}{{ item.rowNum }}排{{ item.colNum }}座</span
              >
            </span>
          </div>
          <div>
            <span>付款金额</span>
            <span>￥{{ order.totalPrice }}</span>
          </div>
          <div>
            <span>订单状态</span>
            <span>{{ orderStatus[order.status] }}</span>
          </div>
        </div>
        <div class="invoice-wrap">
          <el-button
            class="invoice-btn"
            @click="invoiceHandle(order)"
            size="mini"
            type="text"
            v-if="order.status === 'PAID' || order.status === 'USED'"
            >点击蓝字填写开票信息</el-button
          >
        </div>
        <div class="line-dashed"></div>
        <img class="qr-code" :src="qrCodeUrl" />
        <div class="tip-text">工作人员扫码核销时，请出示电子票</div>
      </div>
    </div>
    <div class="empty" v-if="!isShow">暂无数据</div>
  </div>
</template>

<script>
import { getOrderInfo, getQrCodeUrl, getTsTicketSeats } from "@/api/index.js";

export default {
  name: "Mine",
  data() {
    return {
      token: "",
      order: {},
      isShow: true,
      qrCodeUrl: "",
      orderStatus: {
        TO_PAY: "未支付",
        PAID: "已支付",
        USED: "已使用",
        CANCELED: "已取消",
        INVALID: "不可用"
      },
      orderId: "",
      seats: []
    };
  },
  created() {
    this.orderId = this.$route.params.id;

    getOrderInfo().then(({ code, data }) => {
      if (code === 0) {
        if (data.length) {
          this.order = data.find(item => +item.id === +this.orderId);
          this.isShow = true;

          this.qrCodeUrl = getQrCodeUrl(this.order.orderNo);

          this.getTsTicketSeats();
        }
      }
    });
  },
  methods: {
    getTsTicketSeats() {
      getTsTicketSeats({
        params: {
          ticketId: this.order.ticketId,
          orderId: this.order.id,
          page: 1,
          limit: 10000
        }
      }).then(({ code, data }) => {
        if (code === 0) {
          this.seats = data;
        }
      });
    },
    invoiceHandle(order) {
      this.$router.push({
        name: "Invoice",
        params: order
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.mine-container {
  background-color: #a6acb8;
  min-height: calc(100vh - 35px);
  padding-top: 1px;
  box-sizing: border-box;
}
.ticket-information-wrap {
  padding-top: 1px;
  margin: 20px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  padding-bottom: 50px;
}

.head-portrait {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: -35px auto 0;
  overflow: hidden;
}

.head-portrait img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-title {
  text-align: center;
  padding: 5px;
}

.ticket-title {
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
  padding: 5px 0;
}

.ticket-information div {
  display: flex;
}
.ticket-information div>span:first-of-type {
  flex-shrink: 0;
  width: 100px;
  padding-left: 10px;
}

.ticket-information .seat {
  display: inline-block;
  padding: 1px 2px 0;
  margin: 2px;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.ticket-information div span {
  padding: 3px 0;
  font-size: 13px;
}

.line-dashed {
  border-top: 1px dotted #999999;
  margin: 20px 15px;
  position: relative;
}

.line-dashed::before {
  content: '';
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  left: -21px;
  top: -6px;
  background-color: #a6acb8;
}

.line-dashed::after {
  content: '';
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  right: -21px;
  top: -6px;
  background-color: #a6acb8;
}

.qr-code {
  display: block;
  width: 150px;
  height: 150px;
  margin: 50px auto 15px;
}

.tip-text {
  font-size: 11px;
  color: #aaaaaa;
  text-align: center;
}
.invoice-wrap {
  text-align: center;
}
.invoice-btn {
  // float: right;
  // margin-right: 10px;
  font-size: 14px;
}
</style>
